<div class="currency__wrapper flex flex-col gap-[0.94rem] md:flex-row">
  <!-- Naira -->
  <div
    class="flex min-w-[17.8125rem] flex-col rounded-[0.9375rem] bg-gradient-to-r from-mango-grey to-[#786440] px-5 py-[1.19rem] text-white"
  >
    <p class="text-xs font-normal leading-[1.125rem]">Naira Portfolio</p>
    <p class="text-3xl font-extrabold leading-[3.75rem]">
      ₦ {{ nairaWalletBalance / 100 | number: '1.2-2' }}
    </p>
  </div>

  <!-- Dollar  -->
  <div
    class="flex min-w-[17.8125rem] flex-col rounded-[0.9375rem] bg-gradient-to-r from-mango-grey to-[#786440] px-5 py-[1.19rem] text-white"
  >
    <p class="text-xs font-normal leading-[1.125rem]">
      Dollar Portfolio Balance
    </p>
    <p class="text-3xl font-extrabold leading-[3.75rem]">
      $ {{ dollarWalletBalance / 100 | number: '1.2-2' }}
    </p>
  </div>
</div>

<!--Search Input and Filter-->
<div class="flex items-baseline gap-4">
  <app-filters [filters]="filterStructure"></app-filters>
  <button
    *ngIf="accountId"
    mango-button
    class="bottom-0 w-fit shrink-0 items-center"
    style="display: flex; align-items: center; padding: 0.64rem"
    [disabled]="isLoading"
    (click)="exportNavReport()"
  >
    <span *ngIf="isLoading" spin></span>
    {{ isLoading ? 'Exporting...' : 'Export Report' }}
  </button>
</div>

<div
  [ngClass]="{
    'flex-row gap-14 pb-10 ':
      (isEmptyNairaChart$ | async) === false ||
      (isEmptyDollarChart$ | async) === false
  }"
  class="my-[0.94rem] flex flex-col items-center gap-3 bg-white p-4 md:flex-row md:flex-wrap md:justify-between"
>
  @if ((isEmptyNairaChart$ | async) === true) {
    <div
      class="inline-flex w-full items-center gap-[0.9rem] rounded-xl bg-mango-floralWhite px-[0.6rem] py-[0.9rem]"
    >
      <img
        src="assets/images/svgs/info-circle--yellow.svg"
        alt=""
        class="h-[1.5625rem] w-[1.5625rem]"
      />
      <p class="text-sm leading-[1.3125rem] text-mango-grey">
        NGN portfolio is zero
      </p>
    </div>
  } @else {
    <div class="h-80 w-80">
      <h3 class="text-center">NGN Portfolio</h3>
      <canvas [id]="ngnCanvasId" height="100" width="100"></canvas>
    </div>
  }

  @if ((isEmptyDollarChart$ | async) === true) {
    <div
      class="inline-flex w-full items-center gap-[0.9rem] rounded-xl bg-mango-floralWhite px-[0.6rem] py-[0.9rem]"
    >
      <img
        src="assets/images/svgs/info-circle--yellow.svg"
        alt=""
        class="h-[1.5625rem] w-[1.5625rem]"
      />
      <p class="text-sm leading-[1.3125rem] text-mango-grey">
        USD portfolio is zero
      </p>
    </div>
  } @else {
    <div class="h-80 w-80">
      <h3 class="text-center">USD Portfolio</h3>
      <canvas [id]="usdCanvasId" height="100" width="100"></canvas>
    </div>
  }
</div>

<!-- table skeleton loader -->
<div *ngIf="isFetchingPortfolioList$ | async" class="mt-[0.94rem]">
  <app-table-skeleton-loader></app-table-skeleton-loader>
</div>

<!-- Table -->
<div
  *ngIf="!(isFetchingPortfolioList$ | async)"
  class="rounded-lg border border-solid border-mango-gray200 bg-white"
>
  <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
      <div
        class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg"
      >
        <table class="min-w-full divide-y divide-gray-300">
          <thead class="bg-gray-50">
            <tr>
              <th
                scope="col"
                class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
              >
                S/N
              </th>
              <th
                scope="col"
                class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
              >
                Description
              </th>
              <th
                scope="col"
                class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
              >
                Value Local
              </th>

              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Fund Name
              </th>
              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                CCY
              </th>

              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Account Type
              </th>

              <th
                scope="col"
                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                CLR
              </th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200 bg-white">
            <!-- Add table body here -->
            @for (portfolio of porfolioList; track $index) {
              <tr>
                <td
                  class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                >
                  {{ $index + 1 }}
                </td>
                <!-- Description -->
                <td
                  class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                >
                  {{ portfolio.description || '—' }}
                </td>

                <!-- Value Local  -->
                <td
                  class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                >
                  @if (portfolio.ccy === 'NGN') {
                    ₦
                    {{ (portfolio.valueLocal / 100 | number: '1.2-2') || '—' }}
                  } @else {
                    $
                    {{ (portfolio.valueLocal / 100 | number: '1.2-2') || '—' }}
                  }
                </td>

                <!-- Fund Name -->
                <td
                  class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                >
                  {{ portfolio.fundName || '—' }}
                </td>

                <!-- Currency -->
                <td
                  class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                >
                  {{ portfolio.ccy || '—' }}
                </td>

                <!-- Account Type -->
                <td
                  class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                >
                  {{ portfolio.AccountType || '—' }}
                </td>

                <!-- CLR  -->
                <td
                  class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                >
                  {{ portfolio.clr || '—' }}
                </td>
              </tr>
            }
          </tbody>
        </table>

        <div
          *ngIf="
            porfolioList.length === 0 && !(isFetchingPortfolioList$ | async)
          "
          class="flex w-full px-6 pb-6 pt-6"
        >
          No portfolio data found for this account.
        </div>

        <!-- <div
          *ngIf="isFetchingPortfolioList$ | async"
          class="flex w-full px-6 pb-6 pt-6"
        >
          ...Fetching the portfolio for this account. Please wait.
        </div> -->
      </div>
    </div>
  </div>
</div>
