import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import {
  ActivatedRoute,
  Router,
  RouterOutlet,
} from '@angular/router';
import { Pagination } from '@core/models/pagination.model';
import { BreadCrumbService } from '@core/services/bread-crumb.service';
import { UserService } from '@core/services/user.service';
import { BaseSelectDirective } from '@shared/directives/base-select.directive';
import { ButtonDirective } from '@shared/directives/button.directive';
import { InputDateDirective } from '@shared/directives/input-date.directive';
import {
  DropDownModel,
  DropdownComponent,
} from '@shared/ui/dropdown/dropdown.component';
import { InputLabelComponent } from '@shared/ui/input-label/input-label.component';
import { BehaviorSubject, debounceTime, distinctUntilChanged, Observable, Subscription } from 'rxjs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableNavigatorComponent } from '@shared/ui/table-navigator/table-navigator.component';
import { dropdownMenuAnimation } from '@shared/animations/animations';
import { SearchPipe } from '@shared/pipes/search.pipe';
import { FiltersComponent } from '../shared/ui/filters/filters.component';
import { IFILTER } from 'app/app.constants';
import {
  ViewProfileListData,
  ViewProfileListDatum,
} from '@core/models/admin/users';
import { TableSkeletonLoaderComponent } from "../shared/ui/table-skeleton-loader/table-skeleton-loader.component";
import { LoadingService } from '@core/services/loading.service';

@Component({
  selector: 'app-user-customers',
  standalone: true,
  // changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './customers.component.html',
  styleUrl: './customers.component.scss',
  animations: [dropdownMenuAnimation],
  imports: [
    RouterOutlet,
    CommonModule,
    DropdownComponent,
    InputLabelComponent,
    BaseSelectDirective,
    InputDateDirective,
    ButtonDirective,
    SearchPipe,
    ReactiveFormsModule,
    FormsModule,
    TableNavigatorComponent,
    FiltersComponent,
    TableSkeletonLoaderComponent
],
})
export class CustomersComponent implements OnInit, OnDestroy {
  filterMenuState: 'visible' | 'hidden' = 'hidden';
  DropdownVisible = false;
  subs: Subscription[] = [];
  usersList: ViewProfileListDatum[] = [];
  private totalCountSubject = new BehaviorSubject<number>(0);
  totalCount = 0;
  totalCustomers = 0;
  totalCount$: Observable<number> = this.totalCountSubject.asObservable();
  isFetchingCustomerList$!: Observable<boolean>;
  adminService = inject(UserService);
  change = inject(ChangeDetectorRef);
  router = inject(Router);
  activatedRoute = inject(ActivatedRoute);
  breadCrumbService = inject(BreadCrumbService);
  private loadingService = inject(LoadingService);
  currentPage: number = 1;
  isFilterActive: boolean = false;
  isFetchingListSubject$ = new BehaviorSubject<boolean>(false);
  isFetchingList$ = this.isFetchingListSubject$.asObservable();

  filterStructure: IFILTER[] = [
    {
      label: 'Status',
      filterKey: 'status',
      type: 'dropdown',
      dropdown: [
        { key: 'activated', value: 'Activated' },
        { key: 'pending', value: 'Pending' },
      ],
    },
  ];
   constructor(){
     this.isFetchingCustomerList$ =
      this.loadingService.getLoadingObservable('view-profile-list');
  }

  ngOnInit() {
    this.breadCrumbService.setPageBreadCrumb(this.activatedRoute);
    this.getAdminList(new Pagination());
    this.customerFilterEventListener();
  }

  

customerFilterEventListener() {
  this.loadingService.setLoading("view-profile-list",true)
  this.activatedRoute.queryParams
    .pipe(
      debounceTime(300), // Debounce to limit API calls
      distinctUntilChanged() // Only trigger when query params change
    )
    .subscribe((params:any) => {
      let query = '';

      this.filterStructure.forEach(filter => {
        if (params[filter.filterKey]) {
          query += `${filter.filterKey}=${params[filter.filterKey]}&`;
        }
      });

      if (params['search']) {
        query += `search_text=${params['search']}&`;
      }

      const rawPage = params['page'] ? parseInt(params['page']) : 1;
      const page = rawPage > 1 ? rawPage - 1 : 0;
      const take = 10;
      const skip = page * take;

      query += `skip=${skip}&take=${take}`;

      if (query) {
        this.isFilterActive = true;
        const sub = this.adminService.filterProfileList(query).subscribe({
          next: (res: any) => {
             this.usersList = res.data;
            // this.customerListSubject.next(res.data.data);
            this.loadingService.setLoading("view-profile-list",false);
            this.totalCountSubject.next(res.total_count);
            this.change.detectChanges();
          },
          error: (err) => {
            this.loadingService.setLoading("view-profile-list",false);
            console.error(err);
          },
        });
        this.subs.push(sub);
      } else {
        this.getAdminList(new Pagination());
      }
    });
}


  
  registerationDropdownActions(): DropDownModel[] {
    return [
      {
        title: 'Individual',
        action: () => this.goToIndividualReg(),
        show: true,
      },
      {
        title: 'Corporate',
        action: () => this.goToCorporateReg(),
        show: true,
      },
    ];
  }
  goToIndividualReg() {
    this.router.navigateByUrl('/admin/accounts/ind-personal-details');
  }
  goToCorporateReg() {
    this.router.navigateByUrl('/admin/accounts/corp-company-details');
  }
  
navigate(currentPage: number) {
  if (this.isFilterActive) {
    this.router.navigate([], {
      queryParams: { page: currentPage },
      queryParamsHandling: 'merge',
    });
  } else {
    const skip = (currentPage - 1) * 10;
    this.getAdminList(new Pagination({ skip }));
  }
}

  getAdminList(page: Pagination = new Pagination()) {
    this.isFetchingListSubject$.next(true);
    const sub = this.adminService.viewProfileList(page).subscribe({
      next: (res) => {
        this.isFetchingListSubject$.next(false);
        this.usersList = res.data;
        this.totalCountSubject.next(res.total_count);
        this.totalCustomers = res.total_count;
        this.change.detectChanges();
      },
      error: () => {
        this.isFetchingListSubject$.next(false);
      },
    });
    this.subs.push(sub);
  }

  
  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}

