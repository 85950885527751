<div>
  <div class="relative z-50 lg:hidden" role="dialog" aria-modal="true">
    <div
      class="fixed inset-0 bg-gray-900/80"
      [@offCanvasBackdropAnimation]="menuState"
      [ngClass]="menuState === 'hidden' ? 'hidden' : ''"
    ></div>

    <div class="fixed inset-0 flex" [@offCanvasMenuAnimation]="menuState">
      <div
        class="relative mr-16 flex w-full max-w-xs flex-1"
        [@offCanvasMenuAnimation]="menuState"
      >
        <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
          <button
            type="button"
            class="-m-2.5 p-2.5"
            (click)="toggleSidebarMenu()"
            [@closeButtonAnimation]="buttonState"
          >
            <span class="sr-only">Close sidebar</span>
            <svg
              class="h-6 w-6 text-white"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        <!-- START-  Mobile menu -->
        <!-- Sidebar component, swap this element with another sidebar if you like -->
        <div
          [@offCanvasMenuAnimation]="menuState"
          class="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4"
        >
          <div class="flex h-16 shrink-0 items-center">
            <img
              class="h-8 w-auto"
              src="assets/images/mango-logo--tinified.png"
              alt="Mango Asset Management Logo"
            />
          </div>
          <nav class="flex flex-1 flex-col">
            <ul role="list" class="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" class="-mx-2 space-y-1">
                  <li>
                    <a
                      routerLink="/admin/home"
                      routerLinkActive="bg-mango-lightOrange text-mango "
                      class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
                    >
                      <img
                        src="assets/images/svgs/statistics-chart--duotone.svg"
                        alt=""
                      />
                      Dashboard
                    </a>
                  </li>

                  <!-- Products dropdown - mobile -->
                  <li>
                    <a
                      (click)="toggleProductsDropdown()"
                      class="group flex cursor-pointer gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
                    >
                      <img src="assets/images/svgs/box--duotone.svg" alt="" />
                      Products

                      <img
                        [ngClass]="{
                          'rotate-180': productMenuState === 'hidden'
                        }"
                        class="dropdown--toggle-icon rotate-180"
                        src="assets/images/svgs/chevron-up.svg"
                        alt=""
                      />
                    </a>
                  </li>

                  <!-- Products dropdown menu items - mobile -->
                  <div
                    class="dropdown__wrapper"
                    [ngClass]="
                      productMenuState === 'visible' ? 'menu-open' : ''
                    "
                  >
                    <div>
                      <li>
                        <a
                          routerLink="/admin/lcm"
                          routerLinkActive="bg-mango-lightOrange text-mango"
                          class="group flex gap-x-3 rounded-md p-2 pl-11 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
                        >
                          LCM
                        </a>
                      </li>

                      <li>
                        <a
                          routerLink="/admin/pms"
                          routerLinkActive="bg-mango-lightOrange text-mango"
                          class="group flex gap-x-3 rounded-md p-2 pl-11 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
                        >
                          PMS
                        </a>
                      </li>
                    </div>
                  </div>

                  <!-- Accounts dropdown - mobile -->
                  <li>
                    <a
                      (click)="toggleAccountsDropdown()"
                      class="group flex cursor-pointer gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
                    >
                      <img src="assets/images/svgs/box--duotone.svg" alt="" />
                      Accounts

                      <img
                        [ngClass]="{
                          'rotate-180': accountMenuState === 'hidden'
                        }"
                        class="dropdown--toggle-icon rotate-180"
                        src="assets/images/svgs/chevron-up.svg"
                        alt=""
                      />
                    </a>
                  </li>

                  <!-- Accounts dropdown menu items - mobile -->
                  <div
                    class="dropdown__wrapper"
                    [ngClass]="
                      accountMenuState === 'visible' ? 'menu-open' : ''
                    "
                  >
                    <div>
                      <li>
                        <a
                          routerLink="/admin/accounts"
                          routerLinkActive="bg-mango-lightOrange text-mango"
                          class="group flex gap-x-3 rounded-md p-2 pl-11 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
                        >
                          Account List
                        </a>
                      </li>

                      <li>
                        <a
                          routerLink="/admin/approvals"
                          routerLinkActive="bg-mango-lightOrange text-mango"
                          class="group flex gap-x-3 rounded-md p-2 pl-11 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
                        >
                          Approvals
                        </a>
                      </li>
                    </div>
                  </div>

                  <!-- Users dropdown - mobile -->
                  <li>
                    <a
                      (click)="toggleUsersDropdown()"
                      class="group flex cursor-pointer gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
                    >
                      <img
                        src="assets/images/svgs/users-group--duotone.svg"
                        alt=""
                      />
                      Users

                      <img
                        [ngClass]="{ 'rotate-180': userMenuState === 'hidden' }"
                        class="dropdown--toggle-icon rotate-180"
                        src="assets/images/svgs/chevron-up.svg"
                        alt=""
                      />
                    </a>
                  </li>

                  <!-- Users dropdown menu items - mobile -->
                  <div
                    class="dropdown__wrapper"
                    [ngClass]="userMenuState === 'visible' ? 'menu-open' : ''"
                  >
                    <div>
                      <li>
                        <a
                          routerLink="/admin/customers"
                          routerLinkActive="bg-mango-lightOrange text-mango"
                          class="group flex gap-x-3 rounded-md p-2 pl-11 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
                        >
                          Mobile App Users
                        </a>
                      </li>

                      <li>
                        <a
                          routerLink="/admin/users"
                          routerLinkActive="bg-mango-lightOrange text-mango"
                          class="group flex gap-x-3 rounded-md p-2 pl-11 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
                        >
                          System Users
                        </a>
                      </li>
                      <li>
                        <a
                          routerLink="/admin/roles"
                          routerLinkActive="bg-mango-lightOrange text-mango"
                          class="group flex gap-x-3 rounded-md p-2 pl-11 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
                        >
                          Roles
                        </a>
                      </li>
                    </div>
                  </div>
                  <!-- START OF REPORT -->
                  <li class="cursor-not-allowed">
                    <a
                      routerLink="/admin/reports"
                      routerLinkActive="bg-mango-lightOrange text-mango"
                      class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
                    >
                      <img
                        src="assets/images/svgs/document-report.svg"
                        alt=""
                      />
                      Reports
                    </a>
                  </li>
                  <!-- END OF REPORT -->

                  <!-- START - updated orders -->
                  <li>
                    <a
                      routerLink="/admin/orders"
                      routerLinkActive="bg-mango-lightOrange text-mango"
                      class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
                    >
                      <img
                        src="assets/images/svgs/clipboard--duotone.svg"
                        alt=""
                      />
                      Orders
                    </a>
                  </li>
                  <!-- END - updated orders -->

                  <!-- START - transactions -->
                  <li>
                    <a
                      (click)="toggleTransactionsDropdown()"
                      class="group flex cursor-pointer gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
                    >
                      <img
                        src="assets/images/svgs/transfer-arrow--duotone.svg"
                        alt=""
                      />
                      Transactions

                      <img
                        [ngClass]="{
                          'rotate-180': transactionsMenuState === 'hidden'
                        }"
                        class="dropdown--toggle-icon rotate-180"
                        src="assets/images/svgs/chevron-up.svg"
                        alt=""
                      />
                    </a>
                  </li>

                  <!-- Transactions dropdown menu items - mobile -->
                  <div
                    class="dropdown__wrapper"
                    [ngClass]="
                      transactionsMenuState === 'visible' ? 'menu-open' : ''
                    "
                  >
                    <div>
                      <li>
                        <a
                          routerLink="/admin/transactions/wallet"
                          routerLinkActive="bg-mango-lightOrange text-mango"
                          class="group flex gap-x-3 rounded-md p-2 pl-11 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
                        >
                          Wallet Transactions
                        </a>
                      </li>

                      <li>
                        <a
                          routerLink="/admin/transactions/cash"
                          routerLinkActive="bg-mango-lightOrange text-mango"
                          class="group flex gap-x-3 rounded-md p-2 pl-11 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
                        >
                          Cash To Wallet
                        </a>
                      </li>
                    </div>
                  </div>

                  <!-- END - transactions -->

                  <li>
                    <a
                      routerLinkActive="bg-mango-lightOrange text-mango"
                      class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
                    >
                      <img
                        src="assets/images/svgs/settings--duotone.svg"
                        alt=""
                      />
                      Settings
                    </a>
                  </li>
                </ul>
              </li>

              <li class="mt-auto">
                <img
                  class="dashboard-svg"
                  src="assets/images/svgs/dashboard-side-illustration.svg"
                  alt=""
                />
              </li>
            </ul>
          </nav>
        </div>
        <!-- END  Mobile menu -->
      </div>
    </div>
  </div>

  <!-- START - Static sidebar for Desktop -->
  <div class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
    <!-- Sidebar component, swap this element with another sidebar if you like -->
    <div
      class="flex grow flex-col gap-y-5 overflow-y-auto border-gray-200 bg-white px-6 pb-4 pt-4"
    >
      <div class="flex h-16 shrink-0 items-center">
        <img
          class="h-8 w-auto"
          src="assets/images/mango-logo--tinified.png"
          alt="Mango Asset Management Logo"
        />
      </div>
      <nav class="flex flex-1 flex-col">
        <ul role="list" class="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" class="-mx-2 space-y-1">
              <li class="cursor-not-allowed">
                <a
                  routerLink="/admin/home"
                  routerLinkActive="bg-mango-lightOrange text-mango"
                  class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
                >
                  <img
                    src="assets/images/svgs/statistics-chart--duotone.svg"
                    alt=""
                  />
                  Dashboard
                </a>
              </li>

              <!-- Products Dropdown - desktop -->
              <li>
                <a
                  (click)="toggleProductsDropdown()"
                  class="group flex cursor-pointer gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
                >
                  <img src="assets/images/svgs/box--duotone.svg" alt="" />
                  Products

                  <img
                    [ngClass]="{ 'rotate-180': productMenuState === 'hidden' }"
                    class="dropdown--toggle-icon rotate-180"
                    src="assets/images/svgs/chevron-up.svg"
                    alt=""
                  />
                </a>
              </li>
              <!-- Products dropdown menu items -->
              <div
                class="dropdown__wrapper"
                [ngClass]="productMenuState === 'visible' ? 'menu-open' : ''"
              >
                <div>
                  <li>
                    <a
                      routerLink="/admin/lcm"
                      routerLinkActive="bg-mango-lightOrange text-mango"
                      class="group flex gap-x-3 rounded-md p-2 pl-11 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
                    >
                      LCM
                    </a>
                  </li>

                  <li>
                    <a
                      routerLink="/admin/pms"
                      routerLinkActive="bg-mango-lightOrange text-mango"
                      class="group flex gap-x-3 rounded-md p-2 pl-11 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
                    >
                      PMS
                    </a>
                  </li>
                </div>
              </div>

              <!-- separator -->

              <!-- Accounts Dropdown -->
              <li>
                <a
                  (click)="toggleAccountsDropdown()"
                  class="group flex cursor-pointer gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
                >
                  <img src="assets/images/svgs/box--duotone.svg" alt="" />
                  Accounts

                  <img
                    [ngClass]="{ 'rotate-180': accountMenuState === 'hidden' }"
                    class="dropdown--toggle-icon rotate-180"
                    src="assets/images/svgs/chevron-up.svg"
                    alt=""
                  />
                </a>
              </li>
              <!-- Accounts dropdown menu items -->
              <div
                class="dropdown__wrapper"
                [ngClass]="accountMenuState === 'visible' ? 'menu-open' : ''"
              >
                <div>
                  <li>
                    <a
                      routerLink="/admin/accounts"
                      routerLinkActive="bg-mango-lightOrange text-mango"
                      class="group flex gap-x-3 rounded-md p-2 pl-11 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
                    >
                      Account List
                    </a>
                  </li>

                  <li>
                    <a
                      routerLink="/admin/approvals"
                      routerLinkActive="bg-mango-lightOrange text-mango"
                      class="group flex gap-x-3 rounded-md p-2 pl-11 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
                    >
                      Approvals
                    </a>
                  </li>
                </div>
              </div>

              <!-- Users dropdown -->
              <li>
                <a
                  (click)="toggleUsersDropdown()"
                  class="group flex cursor-pointer gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
                >
                  <img
                    src="assets/images/svgs/users-group--duotone.svg"
                    alt=""
                  />
                  Users

                  <img
                    [ngClass]="{ 'rotate-180': userMenuState === 'hidden' }"
                    class="dropdown--toggle-icon rotate-180"
                    src="assets/images/svgs/chevron-up.svg"
                    alt=""
                  />
                </a>
              </li>
              <!-- Users dropdown menu items -->
              <div
                class="dropdown__wrapper"
                [ngClass]="userMenuState === 'visible' ? 'menu-open' : ''"
              >
                <div>
                  <li>
                    <a
                      routerLink="/admin/customers"
                      routerLinkActive="bg-mango-lightOrange text-mango"
                      class="group flex gap-x-3 rounded-md p-2 pl-11 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
                    >
                      Mobile App Users
                    </a>
                  </li>

                  <li>
                    <a
                      routerLink="/admin/users"
                      routerLinkActive="bg-mango-lightOrange text-mango"
                      class="group flex gap-x-3 rounded-md p-2 pl-11 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
                    >
                      System Users
                    </a>
                  </li>
                  <li>
                    <a
                      routerLink="/admin/roles"
                      routerLinkActive="bg-mango-lightOrange text-mango"
                      class="group flex gap-x-3 rounded-md p-2 pl-11 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
                    >
                      Roles
                    </a>
                  </li>
                </div>
              </div>
              <!-- REPORT -->
              <li class="cursor-not-allowed">
                <a
                  routerLink="/admin/reports"
                  routerLinkActive="bg-mango-lightOrange text-mango"
                  class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
                >
                  <img src="assets/images/svgs/document-report.svg" alt="" />
                  Reports
                </a>
              </li>
              <!-- END OF REPORT  -->

              <!-- START Orders dropdown -->
              <li class="cursor-not-allowed">
                <a
                  routerLink="/admin/orders"
                  routerLinkActive="bg-mango-lightOrange text-mango"
                  class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
                >
                  <img src="assets/images/svgs/clipboard--duotone.svg" alt="" />
                  Orders
                </a>
              </li>
              <!-- END - Orders -->

              <!-- START - Transactions -->
              <li>
                <a
                  (click)="toggleTransactionsDropdown()"
                  class="group flex cursor-pointer gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
                >
                  <img
                    src="assets/images/svgs/transfer-arrow--duotone.svg"
                    alt=""
                  />
                  Transactions

                  <img
                    [ngClass]="{
                      'rotate-180': transactionsMenuState === 'hidden'
                    }"
                    class="dropdown--toggle-icon rotate-180"
                    src="assets/images/svgs/chevron-up.svg"
                    alt=""
                  />
                </a>
              </li>

              <!-- START - Transactions dropdown menu items -->
              <div
                class="dropdown__wrapper"
                [ngClass]="
                  transactionsMenuState === 'visible' ? 'menu-open' : ''
                "
              >
                <div>
                  <li>
                    <a
                      routerLink="/admin/transactions/wallet"
                      routerLinkActive="bg-mango-lightOrange text-mango"
                      class="group flex gap-x-3 rounded-md p-2 pl-11 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
                    >
                      Wallet Transactions
                    </a>
                  </li>

                  <li>
                    <a
                      routerLink="/admin/transactions/cash"
                      routerLinkActive="bg-mango-lightOrange text-mango"
                      class="group flex gap-x-3 rounded-md p-2 pl-11 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
                    >
                      Cash To Wallet
                    </a>
                  </li>
                </div>
              </div>
              <!-- END - Transactions dropdown menu items -->
              <!-- END - Transactions -->

              <li class="cursor-not-allowed">
                <a
                  routerLinkActive="bg-mango-lightOrange text-mango"
                  class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
                >
                  <img src="assets/images/svgs/settings--duotone.svg" alt="" />
                  Settings
                </a>
              </li>
            </ul>
          </li>

          <li class="mt-auto">
            <img
              class="dashboard-svg"
              src="assets/images/svgs/dashboard-side-illustration.svg"
              alt=""
            />
          </li>
        </ul>
      </nav>
    </div>
  </div>
  <!-- END - Static sidebar for Desktop -->

  <div class="lg:pl-72">
    <div class="sticky top-0 z-40 lg:mt-4 lg:px-5">
      <div
        class="flex h-16 items-center gap-x-4 border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-0 lg:shadow-none"
      >
        <button
          type="button"
          (click)="toggleSidebarMenu()"
          class="-m-2.5 p-2.5 text-gray-700 lg:hidden"
        >
          <span class="sr-only">Open sidebar</span>
          <svg
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        </button>

        <!-- Separator -->
        <div class="h-6 w-px bg-gray-200 lg:hidden" aria-hidden="true"></div>

        <div
          class="flex flex-1 justify-end gap-x-4 self-stretch bg-mango-paleWhiteFC lg:gap-x-6"
        >
          <!-- Breadcrump -->
          <div class="hidden lg:relative lg:flex lg:flex-1 lg:items-center">
            <span
              class="cursor-pointer py-1 pl-0 pr-2 hover:text-mango"
              (click)="breadCrumbService.navigateToMain()"
            >
              {{ breadCrumbService.currentPage()?.main || 'Dashboard' }}
            </span>
            <span class="mx-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
              >
                <path
                  d="M5.83331 18.8333L14.1666 2.16666"
                  stroke="#D0D5DD"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span
              [ngClass]="
                !breadCrumbService.currentPage()?.primary
                  ? 'bg-mango-lightOrange text-mango'
                  : ''
              "
              class="cursor-pointer rounded-md px-2 py-1 hover:text-mango"
              (click)="breadCrumbService.navigateToNext()"
            >
              {{ breadCrumbService.currentPage()?.next || '..' }}
            </span>

            <span *ngIf="breadCrumbService.currentPage()?.primary" class="mx-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
              >
                <path
                  d="M5.83331 18.8333L14.1666 2.16666"
                  stroke="#D0D5DD"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span
              *ngIf="breadCrumbService.currentPage()?.primary"
              class="rounded-md bg-mango-lightOrange px-2 py-1 text-mango"
              (click)="breadCrumbService.navigateToPrimary()"
              >{{ breadCrumbService.currentPage()?.primary || '..' }}</span
            >
          </div>
          <div class="flex items-center gap-x-4 lg:gap-x-6">
            <!-- Profile dropdown -->
            <div class="relative">
              <button
                #toggleButton
                (click)="toggleNavigationMenu()"
                type="button"
                class="-m-1.5 flex items-center p-1.5 focus-visible:outline-mango"
                id="user-menu-button"
                aria-expanded="false"
                aria-haspopup="true"
              >
                <span class="sr-only">Open user menu</span>
                <span class="hidden lg:mr-5 lg:flex lg:flex-col">
                  <span>{{ userName }}</span>
                  <span>{{
                    userRoles.length > 1
                      ? userRoles[0].role.name + '+'
                      : userRoles[0].role.name
                  }}</span>
                </span>
                <img
                  class="h-8 w-8 rounded-full bg-gray-50"
                  src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  alt=""
                />
                <span class="hidden lg:flex lg:items-center">
                  <svg
                    class="ml-2 h-5 w-5 text-gray-400"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </button>

              <div
                #menu
                [@dropdownMenuAnimation]="navigationMenuState"
                (@dropdownMenuAnimation.start)="popOverOpen($event)"
                [ngClass]="navigationMenuState === 'hidden' ? 'hidden' : ''"
                class="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="user-menu-button"
                tabindex="-1"
              >
                <!-- Active: "bg-gray-50", Not Active: "" -->
                <a
                  routerLink="/admin/my-profile"
                  class="block px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50"
                  role="menuitem"
                  tabindex="-1"
                  id="user-menu-item-0"
                  >Your profile</a
                >
                <a
                  (click)="logout()"
                  class="block cursor-pointer px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50"
                  role="menuitem"
                  tabindex="-1"
                  id="user-menu-item-1"
                  >Logout</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Desktop Breadcrumb -->
    <main class="pb-10 pt-[0.94rem]">
      <div class="px-4 sm:px-6 lg:px-5">
        <!-- Your content -->
        <!-- Breadcrumb -->
        <nav
          aria-label="breadcrumb"
          class="relative mb-5 flex flex-1 items-center lg:hidden"
        >
          <span class="py-1 pl-0 pr-2">
            {{ mainBreadCrumb ? mainBreadCrumb : 'Dashboard' }}
          </span>
          <span class="mx-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
            >
              <path
                d="M5.83331 18.8333L14.1666 2.16666"
                stroke="#D0D5DD"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <span
            aria-current="page"
            class="rounded-md bg-mango-lightOrange px-2 py-1 text-mango"
            >{{ nextBreadCrumb }}</span
          >
        </nav>

        <router-outlet></router-outlet>
      </div>
    </main>
  </div>
</div>

<app-confirm-dialog
  confirmQuestion="Please log in again to continue using the App"
  confirmText="Log in"
  [isOpen]="(showDialog$ | async)!!"
  [showQuestionGif]="false"
  [isInfoDialog]="true"
  [showCancelButton]="false"
  (dialogAction)="login()"
>
</app-confirm-dialog>
