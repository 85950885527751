<div class="flex grid-cols-2 flex-col gap-4 md:grid md:justify-between">
    <h2
      class="text-nowrap text-2xl font-extrabold leading-[1.3125rem] text-mango-black17"
    >
      Orders
    </h2>
  </div>
  

  
  <!-- Search Input and Filters -->
  <app-filters
  [filters]="filterStructure"
  ></app-filters>
  
  <!-- table skeleton loader -->
  <div *ngIf="loading$ | async" class="mt-[0.94rem]">
    <app-table-skeleton-loader></app-table-skeleton-loader>
  </div>
  
  <!--Table -->
  <div
     *ngIf="(orders$ | async) as ordersList"
    class="mt-[0.94rem] rounded-lg border border-solid border-gray-200 bg-white"
  >
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <div
          class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg"
        >
          <table class="min-w-full leading-normal">
            <thead>
              <tr>
                <th
                  class="border-b-2 border-gray-200 bg-gray-100 px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-600"
                >
                  S/N
                </th>
                <th
                  class="border-b-2 border-gray-200 bg-gray-100 px-5 py-5 text-left text-xs font-semibold tracking-wider text-gray-600"
                >
                  Date Created
                </th>
  
                <th
                  class="border-b-2 border-gray-200 bg-gray-100 px-5 py-5 text-left text-xs font-semibold tracking-wider text-gray-600"
                >
                  Order Type
                </th>
  
                <th
                  class="border-b-2 border-gray-200 bg-gray-100 px-5 py-5 text-left text-xs font-semibold tracking-wider text-gray-600"
                >
                  Payment Source
                </th>
  
                <th
                  class="border-b-2 border-gray-200 bg-gray-100 px-5 py-5 text-left text-xs font-semibold tracking-wider text-gray-600"
                >
                  Products
                </th>
  
                <th
                  class="border-b-2 border-gray-200 bg-gray-100 px-5 py-5 text-left text-xs font-semibold tracking-wider text-gray-600"
                >
                  Amount
                </th>
  
                <th
                  class="border-b-2 border-gray-200 bg-gray-100 px-5 py-5 text-left text-xs font-semibold tracking-wider text-gray-600"
                >
                  Status
                </th>
                <th
                  scope="col"
                  class="relative border-b-2 border-gray-200 bg-gray-100 py-3.5 pl-3 pr-4 sm:pr-6"
                >
                  <span class="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
  
            <tbody>
              <!-- @if (ordersList.length < 1) {
                <tr>
                  <td span="10">No orders found!</td>
                </tr>
              } @else {
                @for (order of ordersList; track order.order_id; let i = $index) {
                 
                } @empty {
                  Empty list of users
                }
              } -->
  
              <ng-container
                *ngIf="ordersList && ordersList.data; else noOrders"
              >
                <tr
                  *ngFor="  let order of ordersList.data;  let i = index "
                  class="border-b border-gray-200 bg-white"
                >
                  <td class="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                    <p>{{ i + 1 }}</p>
                  </td>
                  <td class="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                    <p class="whitespace-no-wrap text-gray-900">
                      {{ order.created_at | date: 'medium' }}
                    </p>
                  </td>
  
                  <td
                    class="sm:pmd:py-4 border-b border-gray-200 bg-white px-2 py-2 text-xs sm:px-3 sm:text-sm md:px-4 lg:px-5"
                  >
                    <span
                      class="relative inline-block px-2 py-1 font-semibold leading-tight text-green-900 sm:px-2"
                    >
                      <span
                        aria-hidden
                        class="absolute inset-0 {{
                          order.type === 'invest' ? 'bg-green-200' : 'bg-orange-300'
                        }} opacity-50 rounded-full"
                      ></span>
                      <span
                        class="relative flex  {{
                          order.type === 'invest'
                            ? 'text-[#027A48]'
                            : 'text-orange-700'
                        }} sm:text-[11px]"
                      >
                        {{ order.type | uppercase }}</span
                      >
                    </span>
                  </td>
  
                  <td class="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                    <p class="whitespace-no-wrap text-gray-900">
                      {{ order.payment_source | uppercase }}
                    </p>
                  </td>
  
                  <td class="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                    <p class="whitespace-no-wrap text-gray-900">
                      {{
                        order?.product ? (order?.product?.name | titlecase) : '—'
                      }}
                    </p>
                  </td>
  
                  <td class="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                    <p class="whitespace-no-wrap text-gray-900">
                      ₦{{ order.amount / 100 | number: '1.2-2' }}
                    </p>
                  </td>
  
                  <td
                    class="sm:pmd:py-4 border-b border-gray-200 bg-white px-2 py-2 text-xs sm:px-3 sm:text-sm md:px-4 lg:px-5"
                  >
                    <span
                      class="relative inline-block px-2 py-1 font-semibold leading-tight text-green-900 sm:px-2"
                    >
                      <span
                        aria-hidden
                        ngClass="{{
                          order.status === 'completed'
                            ? 'bg-green-200'
                            : order.status === 'terminated'
                              ? 'bg-red-300'
                              : order.status === 'initiated'
                                ? 'bg-orange-300'
                                : order.status === 'in_process'
                                  ? 'bg-blue-300'
                                  : ''
                        }}"
                        class="absolute inset-0 rounded-full opacity-50"
                      ></span>
                      <span
                        ngClass="{{
                          order.status === 'completed'
                            ? 'text-[#027A48]'
                            : order.status === 'terminated'
                              ? 'text-red-700'
                              : order.status === 'initiated'
                                ? 'text-orange-900'
                                : order.status === 'in_process'
                                  ? 'text-blue-700'
                                  : ''
                        }}"
                        class="relative flex sm:text-[11px]"
                      >
                        {{ order.status | uppercase }}</span
                      >
                    </span>
                  </td>
  
                  <td
                    class="title relative whitespace-nowrap border-b py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
                  >
                    <div
                      class="relative inline-block w-fit cursor-pointer border-gray-200 bg-white text-left"
                    >
                      <app-dropdown
                        [id]="order.id"
                        [actionList]="initDropdownList(order)"
                      >
                      </app-dropdown>
                    </div>
                  </td>
                </tr>
              </ng-container>
              <ng-template #noOrders>
                <tr>
                  <td colspan="6">No Orders found!</td>
                </tr>
              </ng-template>
            </tbody>
          </table>
          <app-table-navigator
            [total]="ordersList?.total_count || 0"
            (onNavigation)="navigate($event)"
          ></app-table-navigator>
        </div>
      </div>
    </div>
  </div>
  <router-outlet></router-outlet>

  
  
  <app-confirm-dialog
    [confirmQuestion]="dialogQuestion"
    confirmText="Yes, Reject Order"
    [isOpen]="isRejectDialogOpen"
    bgConfirmButton="danger"
    [isDialogLoading]="isRejectInProgress"
    dialogLoadingText="Rejecting Order, please wait..."
    (close)="isRejectDialogOpen = false; orderId = null; dialogQuestion = null"
    (dialogAction)="rejectOrder('terminated')"
    [isHTMLQuestion]="true"
  >
  </app-confirm-dialog>
  
  <app-confirm-dialog
    [confirmQuestion]="dialogQuestion"
    confirmText="Yes, Approve Order"
    [isOpen]="isApproveDialogOpen"
    [isDialogLoading]="isApproveInProgress"
    dialogLoadingText="Approving Order, please wait..."
    (close)="isApproveDialogOpen = false; orderId = null; dialogQuestion = null"
    (dialogAction)="approveOrder('completed')"
    [isHTMLQuestion]="true"
  >
  </app-confirm-dialog>
  