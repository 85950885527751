import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import {
  ActivatedRoute,
  Router,
  RouterOutlet,
} from '@angular/router';
import { NotificationFacade } from '@core/facades/notification.facade';
import {
  DropDownModel,
  DropdownComponent,
} from '@shared/ui/dropdown/dropdown.component';
import { Pagination } from '@core/models/pagination.model';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { AccountManagementService } from '@core/services/account-management.service';
import { BreadCrumbService } from '@core/services/bread-crumb.service';
import { ButtonDirective } from '@shared/directives/button.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableNavigatorComponent } from '@shared/ui/table-navigator/table-navigator.component';
import { ConfirmDialogComponent } from '@shared/ui/confirm-dialog/confirm-dialog.component';
import { dropdownMenuAnimation } from '@shared/animations/animations';
import { TableSkeletonLoaderComponent } from '@shared/ui/table-skeleton-loader/table-skeleton-loader.component';
import { LoadingService } from '@core/services/loading.service';
import { FiltersComponent } from '../shared/ui/filters/filters.component';
import { IFILTER } from 'app/app.constants';

@Component({
  selector: 'app-accounts',
  standalone: true,
  templateUrl: './accounts.component.html',
  styleUrl: './accounts.component.scss',
  animations: [dropdownMenuAnimation],
  imports: [
    CommonModule,
    RouterOutlet,
    DropdownComponent,
    ButtonDirective,
    ReactiveFormsModule,
    FormsModule,
    TableNavigatorComponent,
    ConfirmDialogComponent,
    TableSkeletonLoaderComponent,
    FiltersComponent,
  ],
})
export class AccountsComponent implements OnInit, OnDestroy {
  filterMenuState: 'visible' | 'hidden' = 'hidden';
  showAction = false;
  actionList: DropDownModel[] = [];
  subs: Subscription[] = [];
  totalCount: number = 0;
  currentPage: number = 1;
  account: any;
  accountName: string | null = null;
  isExportDialogOpen: boolean = false;
  exportedKycUrl: string | null = null;
  isExportInProgress: boolean = false;
  accountType: string | null = null;
  $index: any;

  isFetchingAccountList$!: Observable<boolean>;

  private accountListSubject = new BehaviorSubject<any>(null);
  private totalCountSubject = new BehaviorSubject<number>(0);
  private accountMetadataSubject = new BehaviorSubject<any>(null);

  accountsList$ = this.accountListSubject.asObservable();
  totalCount$: Observable<number> = this.totalCountSubject.asObservable();
  accountMetadata$: Observable<any> =
    this.accountMetadataSubject.asObservable();

  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private notify = inject(NotificationFacade);
  private breadCrumbService = inject(BreadCrumbService);
  private accountService = inject(AccountManagementService);
  private change = inject(ChangeDetectorRef);
  private loadingService = inject(LoadingService);

  isFilterActive: boolean = false;

  filterStructure: IFILTER[] = [
    {
      filterKey: 'start_date',
      label: 'Start Date',
      type: 'date',
    },
    {
      filterKey: 'end_date',
      label: 'End Date',
      type: 'date',
    },
    {
      filterKey: 'account_type',
      label: 'Account Type',
      type: 'dropdown',
      dropdown: [
        { key: 'corporate', value: 'Corporate' },
        { key: 'individual', value: 'Individual' },
        { key: 'joint', value: 'Joint' },
      ],
    },
    {
      filterKey: 'kyc_status',
      label: 'KYC Status',
      type: 'dropdown',
      dropdown: [
        { key: 'completed', value: 'Completed' },
        { key: 'incomplete', value: 'Incomplete' },
      ],
    },
    {
      filterKey: 'blocked',
      label: 'Access',
      type: 'dropdown',
      dropdown: [
        { key: 'true', value: 'Blocked' },
        { key: 'false', value: 'Unblocked' },
      ],
    },
  ];

  constructor() {
    this.isFetchingAccountList$ =
      this.loadingService.getLoadingObservable('get-account-list');
  }

  ngOnInit() {
    this.breadCrumbService.setPageBreadCrumb(this.activatedRoute);
    this.getAccountList(new Pagination());
    this.getAccountListMetadata();
    this.filterAccountListEventListener();
  }

  toggle() {
    this.change.detectChanges();
  }

  toggleFilterMenu() {
    this.filterMenuState =
      this.filterMenuState === 'hidden' ? 'visible' : 'hidden';
  }

  filterAccountListEventListener() {
    this.activatedRoute.queryParams.subscribe((params) => {
      let query = '';
      if (params[this.filterStructure[0].filterKey]) {
        query = `start_date=${params['start_date']}&`;
      }
      if (params[this.filterStructure[1].filterKey]) {
        query += `end_date=${params['end_date']}&`;
      }
      if (params[this.filterStructure[2].filterKey]) {
        query += `account_type=${params['account_type']}&`;
      }
      if (params[this.filterStructure[3].filterKey]) {
        query += `kyc_status=${params['kyc_status']}&`;
      }
      if (params[this.filterStructure[4].filterKey]) {
        query += `blocked=${params['blocked']}&`;
      }

      if (params['search']) {
        query += `search_text=${params['search']}&`;
      }
      if (query) {
        this.isFilterActive = true;
      }
      // Handle pagination
      const rawPage = params['page'] ? parseInt(params['page']) : 1;
      const page = rawPage > 1 ? rawPage - 1 : 0;
      const take = 10;
      const skip = page * take;

      query += `skip=${skip}&take=${take}`;
      if (query) {
        const sub = this.accountService.filterAccountList(query).subscribe({
          next: (res: any) => {
            this.accountListSubject.next(res.data.data);
            this.totalCountSubject.next(res.data.total_count);
            this.change.detectChanges();
          },
          error: (err) => { },
        });
      } else {
        this.getAccountList(new Pagination());
      }
    });
  }
  getJointAccountName(account: any[]) {
    return account.reduce((p, c, i) => {
      if (i > 0) {
        return (
          p +
          ` & ${c.personal_details.first_name} ${c.personal_details.last_name.toLocaleUpperCase()}`
        );
      }
      return `${c.personal_details.first_name} ${c.personal_details.last_name.toLocaleUpperCase()}`;
    }, '');
  }

  initDropdownList(param: any) {
    return (this.actionList = [
      {
        action: () => {
          this.viewAccountDetails(param);
        },
        title: 'View Details',
        show: true,
      },
      {
        action: () => {
          this.editAccountDetails(param);
        },
        title: 'Edit',
        show: true,
      },
      {
        action: () => {
          this.accountName =
            param.account_type === 'individual'
              ? `${param.user_profile.first_name} ${param.user_profile.last_name}`
              : param.corporate_kyc?.name || 'Empty Account Name';

          this.accountType = param.account_type;

          this.isExportDialogOpen = true;

          type AccountType = 'individual' | 'joint' | 'corporate';
          const exportKYC: Record<AccountType, (id: string) => void> = {
            individual: (id: string) => this.exportIndividualKYC(id),
            joint: (id: string) => {
              this.exportJointKYC(id);
              this.isExportDialogOpen = false;
            },
            corporate: (id: string) => this.exportCorporateKYC(id),
          };

          exportKYC[param.account_type as AccountType]?.(param.id);
        },
        title: 'Export KYC',
        show: true,
      },
      {
        title: 'View Assigned RM',
        action: () => {
          this.router.navigateByUrl(
            `/admin/accounts/${param.account_type}/${param.id}/rm-list`
          );
        },
        show: true,
      },
      {
        title: 'Assign RM',
        action: () => {
          this.router.navigateByUrl(`/admin/accounts/${param.id}/assign-to-rm`);
        },
        show: true,
      },
      {
        title: 'Unblock Account',
          
        action: () => {
          this.changeAccountStatus(param);
        },
        show: param.is_blocked == true,
      },
      {
        title: 'Block Account',
        action: () => {
          this.changeAccountStatus(param);
        },
        show: param.is_blocked == false,
      },
    ]);
  }

  registerationDropdownActions(): DropDownModel[] {
    return [
      {
        title: 'Individual',
        action: () => {
          this.router.navigateByUrl('/admin/accounts/ind-personal-details');
        },
        show: true,
      },
      {
        title: 'Corporate',
        action: () => {
          this.router.navigateByUrl('/admin/accounts/corp-company-details');
        },
        show: true,
      },
      {
        title: 'Joint',
        action: () => {
          this.router.navigateByUrl('/admin/accounts/joint-profile-details');
        },
        show: true,
      },
    ];
  }

  searchAccountList(text: string) {
    const sub = this.accountService.searchAccount(text).subscribe({
      next: (res: any) => {
        this.accountListSubject.next(res.data.data);
        this.totalCountSubject.next(res.data.total_count);
      },
      error: () => { },
    });
    this.subs.push(sub);
  }

  changeAccountStatus(account: any) {
    if (account.is_blocked) {
      const sub = this.accountService.unblockAccount(account.id).subscribe({
        next: (res: any) => {
          this.notify.success('Account unrestricted');
          this.change.detectChanges();
          this.getAccountList();
        },
        error: () => {
          this.notify.error('Account unrestriction failed');

         },
      });
      this.subs.push(sub);
    } else {
      const sub = this.accountService.blockAccount(account.id).subscribe({
        next: (res: any) => {
          this.notify.success('Account restricted');
          this.change.detectChanges();
          this.getAccountList();
        },
        error: () => {
          this.notify.error('Account restriction failed');
         },
      });
      this.subs.push(sub);
    }

  }

  viewAccountDetails(account: any) {
    this.router.navigateByUrl(
      `/admin/accounts/${account.account_type}/${account.id}`
    );
  }

  editAccountDetails(account: any) {
    this.router.navigateByUrl(
      `/admin/accounts/${account.account_type}/edit/${account.id}`
    );
  }

  deleteAccountDetails(account: any) {
    this.notify.error('Action not implemented');
  }

  getAccountListMetadata() {
    const sub = this.accountService.getAccountListMetaData().subscribe({
      next: (res) => {
        this.accountMetadataSubject.next(res.data);
      },
      error: () => { },
    });
    this.subs.push(sub);
  }

  getAccountList(page: Pagination = new Pagination()) {
    const sub = this.accountService.viewAccountList(page).subscribe({
      next: (res) => {
        this.accountListSubject.next(res.data.data);
        this.totalCountSubject.next(res.data.total_count);
      },
      error: () => { },
    });
    this.subs.push(sub);
  }

  exportIndividualKYC(accountId: string) {
    this.isExportInProgress = true;
    const sub = this.accountService.exportIndividualKyc(accountId).subscribe({
      next: (response) => {
        this.isExportInProgress = false;
        this.exportedKycUrl = response.data.statement;

        this.change.detectChanges();
      },
      error: () => {
        this.isExportDialogOpen = false;
        this.isExportInProgress = false;
        this.exportedKycUrl = null;
        this.change.detectChanges();
      },
    });

    this.subs.push(sub);
  }

  exportCorporateKYC(accountId: string) {
    this.isExportInProgress = true;
    const sub = this.accountService.exportCorporateKyc(accountId).subscribe({
      next: (response) => {
        this.isExportInProgress = false;
        this.exportedKycUrl = response.data.statement;

        this.change.detectChanges();
      },
      error: () => {
        this.isExportDialogOpen = false;
        this.isExportInProgress = false;
        this.exportedKycUrl = null;
        this.change.detectChanges();
      },
    });

    this.subs.push(sub);
  }
  exportJointKYC(accountId: string) {
    this.notify.info('API unavailable');
    // this.isExportInProgress = true;
    // const sub = this.accountService.exportJointKyc(accountId).subscribe({
    //   next: (response) => {
    //     this.isExportInProgress = false;
    //     this.exportedKycUrl = response.data.statement;
    //     this.change.detectChanges();
    //   },
    //   error: () => {
    //     this.isExportDialogOpen = false;
    //     this.isExportInProgress = false;
    //     this.exportedKycUrl = null;
    //     this.change.detectChanges();
    //   },
    // });
    // this.subs.push(sub);
  }

  assignRM() {
    this.notify.info('Action not yet implemented');
  }

  openKycDocument() {
    if (this.exportedKycUrl) {
      window.open(this.exportedKycUrl, '_blank');
    }
  }

  navigate(currentPage: number) {
    if (this.isFilterActive) {
      this.router.navigate([], {
        queryParams: { page: currentPage },

        queryParamsHandling: 'merge',
      });
    } else {
      this.getAccountList(new Pagination({ skip: currentPage * 10 - 10 }));
    }
  }


  exportReport() {
    this.router.navigateByUrl(`${this.router.url}/export-report`);
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
