<div
  class="grid grid-cols-2 justify-between gap-4 pb-1 md:grid md:justify-between"
>
  <h2
    style="transform: translate(0px, 4px)"
    class="text-nowrap text-2xl font-extrabold leading-[1.3125rem] text-mango-black17"
  >
    Mobile App Users
  </h2>

  <!-- <button
    (click)="addCustomerDropdown.toggle()"
    type="button"
    mango-button
    class=""
  >
    + Add customer
  </button> -->

  <app-dropdown
    class="w-fit justify-self-end text-nowrap"
    [id]="'addCustomerDropdown'"
    [buttonTitle]="'+ Add customer'"
    [actionList]="registerationDropdownActions()"
  ></app-dropdown>
</div>

<div class="grid gap-5 lg:grid-cols-3">
  <div class="rounded-md bg-[#EEF4FF] p-[38px] shadow-sm">
    <!-- Your chart component goes here -->
    <div>
      <p class="text-">Total Customers</p>
    </div>
    <div>
      <h1 class="number-stat-font text-4xl">{{ totalCustomers | number }}</h1>
    </div>
  </div>
  <div class="rounded-md bg-[#FFF6E8] p-[38px] shadow-sm">
    <!-- Your chart component goes here -->
    <div>
      <p>Fully Registered Customers</p>
    </div>
    <div>
      <h1 class="number-stat-font text-4xl">15</h1>
    </div>
  </div>
  <div
    class="flex items-center rounded-md bg-[#E5FFE6] p-[38px] shadow-sm sm:inline-flex"
  >
    <!-- Your chart component goes here -->

    <div>
      <div>
        <p>Active Now</p>
      </div>
      <div>
        <h1 class="number-stat-font text-4xl">15</h1>
      </div>
    </div>

    <!-- images at the End (Right) -->

    <div class="ml-auto flex">
      <div class="relative h-9 w-9 overflow-hidden rounded-full">
        <img
          class="h-full w-full bg-black object-cover"
          src="assets/images/man--small.png"
          alt="Image 1"
        />
      </div>
      <div class="relative -ml-3 h-9 w-9 overflow-hidden rounded-full">
        <img
          class="h-full w-full bg-black object-cover"
          src="assets/images/man--small.png"
          alt="Image 2"
        />
      </div>
      <div class="relative -ml-3 h-9 w-9 overflow-hidden rounded-full">
        <img
          class="h-full w-full bg-black object-cover"
          src="assets/images/man--small.png"
          alt="Image 3"
        />
      </div>
      <div class="relative -ml-3 h-9 w-9 overflow-hidden rounded-full">
        <img
          class="h-full w-full bg-black object-cover"
          src="assets/images/man--small.png"
          alt="Image 4"
        />
      </div>
    </div>
  </div>
</div>

<!-- Search Input and Filters -->
<app-filters class="flex-1" [filters]="filterStructure"></app-filters>

<div *ngIf="isFetchingCustomerList$ | async" class="mt-[0.94rem]">
  <app-table-skeleton-loader></app-table-skeleton-loader>
</div>

<!-- Search Input and Filters -->
<!-- <div class="mt-[0.94rem] inline-flex flex-wrap gap-x-3">
  <div class="relative rounded-md">
    <div
      class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-[0.88rem]"
    >
      <img src="assets/images/svgs/search.svg" alt="" />
    </div>
    <input
      type="search"
      name="search"
      class="block w-full rounded-lg border-0 py-[0.62rem] pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-base placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-mango focus-visible:ring-mango sm:text-sm sm:leading-6 xl:min-w-[400px]"
      placeholder="Search"
      [(ngModel)]="searchText"
    />
  </div>

  <button
    type="button"
    style="
      border: 1px solid #d0d5dd;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    "
    class="flex gap-2 rounded-lg bg-mango-lightOrange px-4 py-[0.62rem] text-mango focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-mango"
  >
    <span>Tier 2</span>
    <img src="assets/images/svgs/x-close--btn.svg" alt="" class="h-5 w-5" />
  </button>

  <button
    type="button"
    style="
      border: 1px solid #d0d5dd;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    "
    class="flex gap-2 rounded-lg bg-white px-4 py-[0.62rem] hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-mango"
  >
    <img src="assets/images/svgs/trash.svg" alt="" class="h-5 w-5" />
    <span>Clear All</span>
  </button>

  <app-filter-dropdown>
    <div>
      <app-input-label labelClass="label--bottom-margin"
        >KYC Tier</app-input-label
      >
      <select base-select class="cursor-pointer">
        <option value="" disabled selected>Select KYC Tier</option>
        <option value="Tier 1">Tier 1</option>
        <option value="Tier 2">Tier 2</option>
      </select>
    </div>

    <div>
      <app-input-label labelClass="label--bottom-margin"
        >Status</app-input-label
      >
      <select base-select class="cursor-pointer">
        <option value="" disabled selected>Select Status</option>
        <option value="activated">Activated</option>
        <option value="Pending">Pending</option>
      </select>
    </div>
  </app-filter-dropdown>
</div> -->

<!-- Client Table -->
<div
  *ngIf="!(isFetchingCustomerList$ | async)"
  style="
    border-radius: 0.5rem;
    border: 1px solid var(--Gray-200, #eaecf0);
    background: var(--Base-White, #fff);
    margin-top: 0.94rem;
  "
>
  <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
      <div
        class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg"
      >
        <table class="min-w-full leading-normal">
          <thead>
            <tr>
              <th
                class="border-b-2 border-gray-200 bg-gray-100 px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-600"
              >
                S/N
              </th>
              <th
                class="border-b-2 border-gray-200 bg-gray-100 px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-600"
              >
                User
              </th>
              <th
                class="border-b-2 border-gray-200 bg-gray-100 px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-600"
              >
                Phone Number
              </th>

              <th
                class="border-b-2 border-gray-200 bg-gray-100 px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-600"
              >
                Email
              </th>

              <th
                class="border-b-2 border-gray-200 bg-gray-100 px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-600"
              >
                Role
              </th>

              <th
                class="border-b-2 border-gray-200 bg-gray-100 px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-600"
              >
                Status
              </th>

              <!-- <th
                class="border-b-2 border-gray-200 bg-gray-100 px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider text-gray-600"
              ></th> -->
            </tr>
          </thead>
          <tbody>
            @for (user of usersList; track user.id; let i = $index) {
              <tr>
                <td class="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                  <p>{{ i + 1 }}</p>
                </td>
                <td class="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                  <p class="whitespace-no-wrap text-gray-900">
                    {{ user.last_name | uppercase }}
                    {{ user.first_name | titlecase }}
                  </p>
                </td>
                <td class="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                  <p class="whitespace-no-wrap text-gray-900">
                    {{ user.phone_number_1 || '-' }}
                  </p>
                </td>
                <td class="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                  <p class="whitespace-no-wrap text-gray-900">
                    {{ user.email }}
                  </p>
                </td>
                <td class="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                  <p class="whitespace-no-wrap text-gray-900">Customer</p>
                </td>
                <td
                  class="border-b border-gray-200 bg-white px-2 py-2 text-xs sm:px-3 sm:py-3 sm:text-sm md:px-4 md:py-4 lg:px-5"
                >
                  <span
                    class="relative inline-block px-2 py-1 font-semibold leading-tight text-green-900 sm:px-2"
                  >
                    <span
                      aria-hidden
                      class="absolute inset-0 {{
                        user.status === 'activated'
                          ? 'bg-green-200'
                          : 'bg-orange-300'
                      }} opacity-50 rounded-full"
                    ></span>
                    <span
                      class="relative flex  {{
                        user.status === 'activated'
                          ? 'text-[#027A48]'
                          : 'text-orange-700'
                      }} sm:text-[11px]"
                    >
                      {{ user.status | uppercase }}</span
                    >
                  </span>
                </td>
              </tr>
            }
          </tbody>
        </table>
        <app-table-navigator
          *ngIf="totalCount$ | async as totalCount"
          [total]="totalCount"
          (onNavigation)="navigate($event)"
        ></app-table-navigator>
      </div>
    </div>
  </div>
</div>
<!--Added Z-index-->
<!-- <div class="fixed top-0 right-0 z-20"> -->
<router-outlet></router-outlet>
<!-- </div> -->
