import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterLinkActive,
  RouterOutlet,
} from '@angular/router';
import { AccountManagementService } from '@core/services/account-management.service';
import { CommonModule } from '@angular/common';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { BreadCrumbService } from '@core/services/bread-crumb.service';
import { NotificationFacade } from '@core/facades/notification.facade';
import { AccountKYC } from '@core/models/admin/account/account.interface';
import { IndividualTabsComponent } from '@shared/ui/individual-tabs/individual-tabs.component';
import { ButtonDirective } from '@shared/directives/button.directive';
import { LoadingService } from '@core/services/loading.service';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Clipboard } from '@angular/cdk/clipboard';
import { DropDownModel, DropdownComponent } from '@shared/ui/dropdown/dropdown.component';
import { StatusComponent } from "../../shared/ui/status/status.component";

@Component({
  selector: 'app-individual-account',
  standalone: true,
  imports: [
    CdkAccordionModule,
    CommonModule,
    IndividualTabsComponent,
    RouterOutlet,
    RouterLink,
    ButtonDirective,
    RouterLinkActive,
    NgxSkeletonLoaderModule,
    DropdownComponent,
    StatusComponent
],
  templateUrl: './individual-account.component.html',
  styleUrl: './individual-account.component.scss',
})
export class IndividualAccountComponent implements OnInit, OnDestroy {
  accountData!: AccountKYC | null;
  subs: Subscription[] = [];
  accountId!: string;
  userDetailsUrl!: string;
  accountsUrl!: string;

  isFetchingAccountInfo$!: Observable<boolean>;

  private activatedRoute = inject(ActivatedRoute);
  private router = inject(Router);
  private breadCrumbService = inject(BreadCrumbService);
  private accountService = inject(AccountManagementService);
  private cdr = inject(ChangeDetectorRef);
  private toast = inject(NotificationFacade);
  private loadingService = inject(LoadingService);
  private clipboard = inject(Clipboard);

  constructor() {
    this.isFetchingAccountInfo$ = this.loadingService.getLoadingObservable(
      'get-individual-account-kyc'
    );
  }

  ngOnInit(): void {
    this.breadCrumbService.setPageBreadCrumb(this.activatedRoute);

    this.accountId = this.activatedRoute.snapshot.params['accountId'];
    if (this.accountId) {
      this.userDetailsUrl = `/admin/accounts/individual/${this.accountId}/details`;
      this.accountsUrl = `/admin/accounts/individual/accounts/${this.accountId}`;
      this.getAccountInfo();
      this.cdr.detectChanges();
    }
  }

  _editAccount() {
    this.router.navigateByUrl(
      `admin/accounts/edit?profileId=${this.accountData?.user_id}&accountId=${this.activatedRoute.snapshot.params['accountId']}&edit=true`
    );
  }

  editAccount() {
    // console.log('edit account');

    this.router.navigateByUrl(
      `/admin/accounts/individual/edit/${this.accountId}`
    );
  }
 getIncomeCategory(annualIncome: string | undefined): string {
    if (!annualIncome) {
      return '—';
    }
    if (annualIncome.includes('Below')) {
      return 'RETAIL';
    }
    if (annualIncome.includes('Above')) {
      return 'HNI';
    }
    const range = annualIncome.split(' - ')[1];
    const upperBound = parseInt(range.replace(/,/g, ''), 10);
    return upperBound > 50000000 ? 'HNI' : 'RETAIL';
}


  getAccountInfo(): void {
    const sub = this.accountService
      .getIndividualAccountInfo(this.accountId)
      .subscribe({
        next: (response: any) => {
          this.accountData = response.data as AccountKYC;
          this.accountService.setAccountKYCData(this.accountData);
          this.cdr.detectChanges();
        },
        error: () => {
          this.toast.error('Failed to fetch account info');
        },
      });

    this.subs.push(sub);
  }

  // getAccountKycTier() {
  //   const sub = this.accountService
  //     .getAccountKycTier(this.accountId)
  //     .subscribe({
  //       next: (response) => {
  //         this.tierLevelSubject$.next(response.data.kyc_levels);
  //       },
  //       error: () => {
  //         this.toast.error('Failed to fetch tier level');
  //       },
  //     });

  //   this.subs.push(sub);
  // }
  copy(accountNumber: string) {
    this.clipboard.copy(accountNumber);
    this.toast.success('Successfully copied to clipboard');
  }

  goBack() {
    history.back();
  }

  registerationDropdownActions(): DropDownModel[] {
    return [
      {
        title: 'Client Directory',
        action: () => {
          this.router.navigateByUrl(`/admin/accounts/client-directory-report/individual/${this.accountId}`);
        },
        show: true,
      },
      {
        title: 'Account Statement',
        action: () => {
          this.router.navigateByUrl(`/admin/accounts/accocunt-statement-report/${this.accountId}`);
        },
        show: true,
      },
      {
        title: 'Nav',
        action: () => {
          this.router.navigateByUrl(`/admin/accounts/nav-report/${this.accountId}`);
        },
        show: true,
      },
      {
        title: 'Order',
        action: () => {
          this.router.navigateByUrl(`/admin/accounts/order-report/${this.accountId}`);
        },
        show: true,
      },
      // {
      //   title: 'Audit Log',
      //   action: () => {
      //     this.router.navigateByUrl('/admin/accounts/joint-profile-details');
      //   },
      //   show: true,
      // },
    ];
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
